import React, { useState } from 'react';
import './Contact.css';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

function Contact() {
  const [formData, setFormData] = useState({
    parentsName: '',
    childsName: '',
    grade: 'Playgroup',
    email: '',
    phone: '',
    address: '',
    message: ''
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_23x5yyv', 'template_nvr92kn', e.target, '1M3bB8NjGBQw9_S5r')
      .then((result) => {
          console.log(result.text);
          Swal.fire({
            icon: "success",
            title: "Message Received",
            showConfirmButton: false,
            timer: 1500
          });
      }, (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!"
          });
      });

    e.target.reset();
  };

  return (
    <div className='contact-page'>
      <header className='height-30'>
        <div className='container pt-5 text-light'>
          <h1 className='text-center fw-semibold'>Get In Touch</h1>
          <p className='text-center mb-5'>Welcome to Woodford Hill School's contact page, where communication meets excellence. We value your feedback and inquiries.</p>
        </div>
      </header>

      <div className='container py-5'>
            <div className='row g-4'>
                <div className='col-lg-6'>
                    <div className='scale-hover-effect'>
                            <div className='fs-3 text-danger mb-3'>Map</div>
                            <div className='text-center'>

                            <iframe title="map" width={"100%"} height={"100%"} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8993087181702!2d36.67655708515246!3d-1.2297870323493045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1f3160dc5beb%3A0xc19d6f029c7b406f!2sWoodford%20Hill%20school!5e0!3m2!1sen!2sus!4v1719860401417!5m2!1sen!2sus" />

                            </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='scale-hover-effect'>
                            <div className='fs-3 text-danger'>Contact Info.</div>
                            <div className='text-center'>

                            <div className='container'>
                    <div >
                      <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#dc3545" class="bi bi-telephone" viewBox="0 0 16 16">
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                        </svg>
                    </div>

                    <div className='p-3 '>

                    <p className='phonesColor'>+254 706 321 788 / +254 723 114 959</p>

                    </div>
                    <div >
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#dc3545" class="bi bi-envelope" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                    </svg>
                    </div>

                    <div className='p-3'>

                    <p className='phonesColor'>contact@woodfordhillschool.co.ke</p>

                    </div>
                  </div>
                </div>





                            </div>
                    </div>
                </div>
            </div>
        </div>


      <div className='bg-secondary bg-opacity-25'>
        <div className='container pt-5 d-flex justify-content-center'>
          <h3 className='text-center fw-semibold'>Leave Us a Message</h3>
        </div>

        <div className="container social-icons-line pb-1"></div>

        <div className='container pb-5 d-flex justify-content-center'>
          <Form id='contact-form' onSubmit={handleSubmit}>
            <Row className='mb-3'>
              <Col sm={12} md={6} className='mb-3 mb-md-0'>
                <Form.Label>Parent/ Guardian's Name</Form.Label>
                <Form.Control
                  name="parentsName"
                  placeholder="Parent/Guardian's name"
                  onChange={handleChange}
                />
              </Col>
              <Col sm={12} md={6}>
                <Form.Label>Child's Name</Form.Label>
                <Form.Control
                  name="childsName"
                  placeholder="Child's name"
                  onChange={handleChange}
                />
              </Col>
              <Col sm={12} md={6}>
                <Form.Label>Grade</Form.Label>
                <Form.Select
                  name="grade"
                  onChange={handleChange}
                  defaultValue="Playgroup"
                >
                  <option value="Playgroup">Playgroup</option>
                  <option value="PP1">PP1</option>
                  <option value="PP2">PP2</option>
                  <option value="Grade 1">Grade 1</option>
                  <option value="Grade 2">Grade 2</option>
                  <option value="Grade 3">Grade 3</option>
                  <option value="Grade 4">Grade 4</option>
                  <option value="Grade 5">Grade 5</option>
                  <option value="Grade 6">Grade 6</option>
                  <option value="Grade 7">Grade 7</option>
                  <option value="Grade 8">Grade 8</option>
                  <option value="Grade 9">Grade 9</option>
                </Form.Select>
              </Col>
            </Row>

            <Form.Group className='mb-3'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                name="email"
                placeholder='Enter email'
                onChange={handleChange}
              />
              <Form.Text className='text-muted'>
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Phone Number</Form.Label>
              <InputGroup>
                <Form.Select aria-label="Country code" defaultValue="+254" name="phoneCode" onChange={handleChange}>
                  <option value="+254">+254 (Kenya)</option>
                  <option value="+256">+256 (Uganda)</option>
                  <option value="+255">+255 (Tanzania)</option>
                </Form.Select>
                <Form.Control
                  name="phone"
                  placeholder='0706 321 788'
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Address</Form.Label>
              <Form.Control
                name="address"
                placeholder='Your address'
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Your Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="message"
                onChange={handleChange}
              />
            </Form.Group>


            <Button variant="danger btn-lg" type='submit'>Submit</Button>
            <input type="hidden" name="class" value={formData.grade} />
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Contact;
