import React from 'react';
import './OurClasses.css';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ChooseSection from '../../components/ChooseSection/ChooseSection';

import art from './scouts-1.jpg';

import Blog1Img from './pp1 1.jpg';
import Blog2Img from './class 8 - 1.jpg';
import Blog3Img from './grade 8.jpg';

const blogs = [
    {
        id: 1,
        img: [Blog1Img],
        title: 'Pre-School',
        description: "In our Pre-School section at Woodford Hill School, we focus on nurturing young minds, fostering early reading skills, and instilling a culture of excellence."
    },
    {
        id: 2,
        img: [Blog2Img],
        title: 'Primary',
        description: "At Woodford Hill School's Primary level, we focus on cultivating holistic students who excel academically and thrive in a supportive learning environment."
    },
    {
        id: 3,
        img: [Blog3Img],
        title: 'Junior School',
        description: "In the Junior School at Woodford Hill, we prepare students for high school by imparting moral education that guides them through their academic journey and beyond."
    }
  ];
  


function OurClasses() {
  return (
    <div className='classes-page'>
        <header className='height-30'>
        <div className='container pt-5 text-light'>
          <h1 className='text-center fw-semibold'>Our Classes</h1>
          <p className='text-center mb-5'>Explore a diverse array of classes aimed at nurturing growth and academic excellence for all students in our school.</p>
        </div>
      </header>

        <div className='container my-5'>
            <div className="row">
                <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                    <h2 className='mb-4 mb-lg-5'>Study with us</h2>
                    <p className='text-justify'>Woodford Hill School stands as a beacon of academic excellence. Over the past three KCPE exams, we have proudly ranked first within Kabete sub-county, with a majority of our graduates advancing to renowned national institutions such as Mang'u, Alliance, and Mary Hill. </p>
                    <p className=' text-justify'>At Woodford, we embrace innovation and forward-thinking educational practices. As early adopters of the new Competency Based Curriculum (CBC), we are at the forefront of educational reform, ensuring that our teaching methods align with modern global standards. Our curriculum is designed not only to impart knowledge but also to foster critical thinking, creativity, and practical skills that are essential for lifelong learning and adaptability in a rapidly evolving world.</p>
                    <p className='mb-4 mb-lg-5 text-justify'>Beyond academics, we believe in nurturing well-rounded individuals who are equipped to make meaningful contributions to society. Our holistic approach to education includes a rich array of co-curricular activities, from sports to arts and community service initiatives. These activities complement our academic programs, providing students with opportunities to explore their passions, develop leadership skills, and cultivate values of teamwork and empathy.</p>
                    <Link to="/apply">
                        <button type='button' className='btn btn-outline-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Apply Now</button>
                    </Link>
                </div>
                <div className='col-lg-6 d-flex justify-content-center'>
                    <img src={art} className='img-fluid w-75' alt="" />
                </div>
            </div>
        </div>

        <div className='bg-secondary bg-opacity-25 text-dark py-5'>
            <ChooseSection />
        </div>

        <div className='py-5'>
            <div className='container d-flex flex-column align-items-center'>
                <h2 className='text-center text-capitalize mb-5'>Departments</h2>
                <div className='row g-4 our-classes-data'>
                    {blogs.map((blog) => (
                        <div key={blog.id} className='col-md-6 col-lg-4 scalar'>
                            <div>
                                <Card className='border-0 shadow our-classes-card'>
                                    <Card.Img variant="top" src={blog.img} />
                                    <Card.Body className='p-md-5'>
                                        <Card.Title>{blog.title}</Card.Title>
                                        <Card.Text className=''>{blog.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    ))}
                </div>
                <Link to="/fee">
                    <button type='button' className='btn btn-outline-danger btn-lg mt-5'>View Fee Structures</button>
                </Link>
            </div>
        </div>

    </div>
  )
}

export default OurClasses