import React, { useState } from 'react';
import './FaqAccordion.css';
import { Accordion } from 'react-bootstrap';

function FaqAccordion() {
    const [activeKey, setActiveKey] = useState('');

    const handleSelect = (eventKey) => {
        setActiveKey(eventKey);

        // Automatically close accordion after 15 seconds
        setTimeout(() => {
            setActiveKey('');
        }, 15000); // 15000 milliseconds = 15 seconds
    };

    return (
        <div className='faq-section'>
            <div className='container d-flex flex-column align-items-center'>
                <h2 className='text-center text-capitalize mb-5'>Frequently asked questions</h2>
                <p className='text-justify mb-5'>Welcome to our Frequently Asked Questions section. Here, we address common queries about Woodford Hill School, covering topics such as admissions, academic support, student-to-teacher ratios, the new Competency-Based Curriculum (CBC), and our wide range of extracurricular activities. We aim to provide you with comprehensive information to help you understand our school better and make informed decisions for your child's education. Please do not hesitate to contact our administration office for more detailed information.</p>
                <Accordion activeKey={activeKey} onSelect={handleSelect} flush>
                    <Accordion.Item eventKey='0'>
                        <Accordion.Header>What are the admission requirements for Woodford Hill School ?</Accordion.Header>
                        <Accordion.Body>
                        Admission to Woodford Hill School requires the completion of an application form, submission of previous school records, and a successful interview with our admissions team.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='1'>
                        <Accordion.Header>How does Woodford Hill School support students' academic progress ?</Accordion.Header>
                        <Accordion.Body>
                        We provide personalized learning plans, regular progress assessments, tutoring, and a supportive learning environment to ensure each student reaches their full potential.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='2'>
                        <Accordion.Header>What is the student-to-teacher ratio at Woodford Hill School ?</Accordion.Header>
                        <Accordion.Body>
                        Our student-to-teacher ratio is 15:1, ensuring that each student receives individual attention and support from our dedicated faculty.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='3'>
                        <Accordion.Header>How is the new CBC implemented at Woodford Hill School ?</Accordion.Header>
                        <Accordion.Body>
                        We have integrated CBC into our teaching methods, focusing on developing critical thinking, creativity, and practical skills through hands-on learning and real-world applications.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='4'>
                        <Accordion.Header>What extracurricular activities are offered at Woodford Hill School ?</Accordion.Header>
                        <Accordion.Body>
                        We offer a wide range of extracurricular activities, including sports, arts, music, drama, swimming and various clubs such as science, debate, scouts and community service.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
}

export default FaqAccordion;
