import React, { useState } from 'react';
import './ApplyNow.css';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

function ApplyNow() {
  const [formData, setFormData] = useState({
    parentsName: '',
    grade: 'Playgroup',
    phone: '',
    phoneCode: '+254',
    address: '',
    day: '',
    month: '',
    year: '',
    currentSchool: '',
    enrol: '2024',
    studentFirstName: '',
    studentMiddleName: '',
    studentSurname: '',
    gender: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'grade') {
      setFormData({
        ...formData,
        grade: value,
        currentSchool: value === 'Playgroup' || value === 'PP1' ? 'Not applicable' : '',
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_23x5yyv', 'template_e9h5dqr', e.target, '1M3bB8NjGBQw9_S5r')
      .then((result) => {
          console.log(result.text);
          Swal.fire({
            icon: "success",
            title: "Thank you For your interest!",
            showConfirmButton: false,
            timer: 1500
          });
          setFormData({
            parentsName: '',
            grade: 'Playgroup',
            phone: '',
            phoneCode: '+254',
            address: '',
            day: '',
            month: '',
            year: '',
            currentSchool: '',
            enrol: '2024',
            studentFirstName: '',
            studentMiddleName: '',
            studentSurname: '',
            gender: '',
          });
      }, (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please check your Internet Connection!",
          });
      });

    e.target.reset();
  };

  const gradeOptions = ["Playgroup", "PP1", "PP2", "Grade 1", "Grade 2", "Grade 3", "Grade 4", "Grade 5", "Grade 6", "Grade 7", "Grade 8", "Grade 9"];
  const yearOptions = Array.from({ length: 17 }, (_, i) => new Date().getFullYear() - i);

  
  return (
    <div className='applyNow-page'>
      <header className='height-30'>
        <div className='container pt-5 text-light'>
          <h1 className='text-center fw-semibold'>Apply Now</h1>
          <p className='text-center mb-5'> We are prepared to welcome your child into our school community. Complete our application form to start the enrollment process.</p>
        </div>
      </header>

      <div className='bg-secondary bg-opacity-25'>
        <div className='container pt-5 d-flex justify-content-center'>
          <h3 className='text-center fw-semibold'>Application Form</h3>
        </div>

        <div className="container social-icons-line pb-1"></div>

        <div className='container pb-5 d-flex justify-content-center'>
          <Form id='applyNow-form' onSubmit={handleSubmit}>
            <Row className='mb-3'>
              <Col sm={12} md={6}>
                <Form.Label>Grade</Form.Label>
                <Form.Select
                  name="grade"
                  onChange={handleChange}
                  value={formData.grade}
                >
                  {gradeOptions.map((grade) => (
                    <option key={grade} value={grade}>{grade}</option>
                  ))}
                </Form.Select>
              </Col>
              <Col sm={12} md={6}>
                <Form.Label>Enrollment Year</Form.Label>
                <Form.Select
                  name="enrol"
                  onChange={handleChange}
                  value={formData.enrol}
                >
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                </Form.Select>
              </Col>
            </Row>

            <Form.Label className='fs-6 mt-5 mb-5 text-uppercase titles'>
              Student's Details
            </Form.Label>
            <Row className='mb-3'>
              <Col sm={12} md={6} className='mb-3'>
                <Form.Label>Student First Name</Form.Label>
                <Form.Control
                  name="studentFirstName"
                  placeholder="First Name"
                  value={formData.studentFirstName}
                  onChange={handleChange}
                />
              </Col>
              <Col sm={12} md={6} className='mb-3'>
                <Form.Label>Student Middle Name</Form.Label>
                <Form.Control
                  name="studentMiddleName"
                  placeholder="Middle Name"
                  value={formData.studentMiddleName}
                  onChange={handleChange}
                />
              </Col>
              <Col sm={12} md={6} className='mb-3'>
                <Form.Label>Student Surname</Form.Label>
                <Form.Control
                  name="studentSurname"
                  placeholder="Surname"
                  value={formData.studentSurname}
                  onChange={handleChange}
                />
              </Col>
              <Col sm={12} md={6}>
                <Form.Label>Gender</Form.Label>
                <Form.Select
                  name="gender"
                  onChange={handleChange}
                  value={formData.gender}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Select>
              </Col>
            </Row>

            <Form.Group className='mb-3'>
            <Form.Label>Date of Birth</Form.Label>
                <InputGroup>
                  <Form.Select
                    name="day"
                    onChange={handleChange}
                    value={formData.day}
                  >
                    <option value="">Day</option>
                    {Array.from({ length: 31 }, (_, i) => i + 1).map(day => (
                      <option key={day} value={day}>{day}</option>
                    ))}
                  </Form.Select>
                  <Form.Select
                    name="month"
                    onChange={handleChange}
                    value={formData.month}
                  >
                    <option value="">Month</option>
                    {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                      <option key={month} value={month}>{month}</option>
                    ))}
                  </Form.Select>
                  <Form.Select
                    name="year"
                    onChange={handleChange}
                    value={formData.year}
                  >
                    <option value="">Year</option>
                    {yearOptions.map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </Form.Select>
                </InputGroup>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Current School</Form.Label>
              <Form.Control
                name="currentSchool"
                placeholder={formData.grade === 'Playgroup' || formData.grade === 'PP1' ? 'Not applicable' : 'Enter Current School'}
                value={formData.grade === 'Playgroup' || formData.grade === 'PP1' ? 'Not applicable' : formData.currentSchool}
                onChange={handleChange}
                readOnly={formData.grade === 'Playgroup' || formData.grade === 'PP1'}
              />
            </Form.Group>

            <Form.Label className='fs-6 mt-5 mb-5 text-uppercase titles'>
              Parent's Details
            </Form.Label>

            <Form.Group className='mb-3'>
              <Form.Label>Parent/ Guardian's Name</Form.Label>
              <Form.Control
                name="parentsName"
                placeholder="Enter Parent/ Guardian's Name"
                value={formData.parentsName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Phone Number</Form.Label>
              <InputGroup>
                <Form.Select aria-label="Country code" name="phoneCode" value={formData.phoneCode} onChange={handleChange}>
                  <option value="+254">+254 (Kenya)</option>
                  <option value="+256">+256 (Uganda)</option>
                  <option value="+255">+255 (Tanzania)</option>
                </Form.Select>
                <Form.Control
                  name="phone"
                  placeholder='0706 321 788'
                  value={formData.phone}
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Address</Form.Label>
              <Form.Control
                name="address"
                placeholder='Your address'
                value={formData.address}
                onChange={handleChange}
              />
            </Form.Group>

            <Button variant="danger btn-lg" type='submit'>Submit</Button>
            <input type="hidden" name="class" value={formData.grade} />
          </Form>
        </div>
      </div>
    </div>
  )
}

export default ApplyNow;
