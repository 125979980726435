import React from 'react';
import './Courses.css';
import { Card } from 'react-bootstrap';
import CBC from './Agriculture.jpg';
import CoCurricular from './pottery.jpg';
import CRE from './grade 8.jpg';
import Computer from './computer.jpg';
import Scouts from './Scouts 2.jpg';
import Swimming from './swimming.jpg';
import CreativeArts from './art school.jpg';
import games from './pre-school.jpg';
import french from './group.jpg';
import chess from './junior 3.jpg';

const courses = [
    {
        id: 1,
        img: [CBC],
        title: 'Competency Based Curriculum (CBC)'
    },
    {
        id: 2,
        img: [CoCurricular],
        title: 'Co-Curricular'
    },
    {
        id: 3,
        img: [CRE],
        title: 'Christian Religious Education'
    },
    {
        id: 4,
        img: [Computer],
        title: 'Computer Tech.'
    },
    {
        id: 5,
        img: [Scouts],
        title: 'Scouts'
    },
    {
        id: 6,
        img: [Swimming],
        title: 'Swimming'
    },
    {
        id: 7,
        img: [CreativeArts],
        title: 'Creative Arts'
    },
    {
        id: 8,
        img: [french],
        title: 'French'
    },
    {
        id: 9,
        img: [chess],
        title: 'Chess'
    },
    {
        id: 10,
        img: [games],
        title: 'Games'
    },
];

function Courses() {
  return (
    <div className='courses-page'>
        <header className='height-30 '>
        <div className='container pt-5 text-light'>
        <h1 className='text-center fw-semibold'>Programmes</h1>
                <p className='text-center mb-5'>Explore Our Diverse Programmes: Discover enriched learning experiences tailored to nurture and inspire young minds effectively.</p>
            </div>
        </header>

        <div className='container py-5'>
            <div className='row g-4 programme-data'>
                {courses.map((course) => (
                    <div key={course.id} className='col-lg-6'>
                        <Card className='text-white shadow programme-card border-0'>
                            <Card.Img src={course.img} alt={course.title} variant='top'/>
                                <Card.Title className='fs-3 m-3 text-danger'>{course.title}</Card.Title>
                            
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Courses;