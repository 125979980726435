import React, { useState } from 'react';
import './FaqAccordion.css';
import { Accordion } from 'react-bootstrap';

function FaqAccordion() {
    const [activeKey, setActiveKey] = useState('');

    const handleSelect = (eventKey) => {
        setActiveKey(eventKey);

        // Automatically close accordion after 15 seconds
        setTimeout(() => {
            setActiveKey('');
        }, 15000); // 15000 milliseconds = 15 seconds
    };

    return (
        <div className='faq-section'>
            <div className='container d-flex flex-column align-items-center'>
                <h2 className='text-center text-capitalize mb-5'>Frequently Asked Questions</h2>
                <p className='text-justify mb-5'>
                    Welcome to our FAQ section. Here, you will find answers to some of the most commonly asked questions about our school, ranging from tuition fees and transportation services to the school uniform and admission process. If you have any further queries, please do not hesitate to contact our administration office for more detailed information.
                </p>
                <Accordion activeKey={activeKey} onSelect={handleSelect} flush>
                    <Accordion.Item eventKey='0'>
                        <Accordion.Header>What are the tuition fees for the upcoming academic year?</Accordion.Header>
                        <Accordion.Body className='text-justify'>
                            The tuition fees for the upcoming academic year vary by grade level. For Pre-School, the fee is Ksh. 15,000 per term. For Primary grades 1-3, the fee is Ksh. 16,000 per term, and for grades 4-6, it is Ksh. 17,000 per term. For Junior School, grades 7 and 8, the fee is Ksh. 23,000 per term. Additional fees may apply for registration and interviews.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='1'>
                        <Accordion.Header>Does the school provide transportation services?</Accordion.Header>
                        <Accordion.Body className='text-justify'>
                            Yes, the school provides transportation services for students. The costs depend on the distance from the school and the specific route. Detailed information about the routes, pick-up and drop-off points, and associated costs can be obtained from the school administration office or the school's website under the transport section.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='2'>
                        <Accordion.Header>Where can I purchase the school uniform and what are the required items?</Accordion.Header>
                        <Accordion.Body className='text-justify'>
                            School uniforms are only purchased at the school administration office. The required uniform items include a school shirt, trousers or skirt, a tie, a sweater, and specific footwear. Detailed lists of required items for each grade level are available on the school's website or can be provided by the school administration office.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='3'>
                        <Accordion.Header>What is the admission process and are there any entrance interviews?</Accordion.Header>
                        <Accordion.Body className='text-justify'>
                            The admission process involves filling out an application form, submitting required documents such as previous school records and birth certificates, and paying a registration fee of Ksh. 3,000. <br /><br />For certain grades, there may be an entrance exam or an interview. Interviews are typically required for PP2 and above, with a fee of Ksh. 1,000. Detailed information about the admission process, required documents, and any entrance assessments can be obtained from the admissions office.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='4'>
                        <Accordion.Header>What are the accepted methods of fee payment and the payment deadlines ?</Accordion.Header>
                        <Accordion.Body className='text-justify'>
                        The school accepts two methods of fee payment which are, bank transfers and mobile money services (such as M-Pesa). <br /><br />Payment deadlines are typically set at the beginning of each term. It is important to adhere to these deadlines to avoid any late fees or disruptions in your child's enrollment. Detailed information on the payment methods, bank details, and payment deadlines can be found on the school's website or obtained from the finance office.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
}

export default FaqAccordion;
