import React from 'react';
import { Card } from 'react-bootstrap';

function Vision({ title, text, image, reverse, bgSecondary }) {
  return (
    <div className={`container py-5 ${bgSecondary ? 'bg-secondary bg-opacity-25' : ''}`}>
      <div className={`row g-4 align-items-center ${reverse ? 'flex-row-reverse' : ''}`}>
        
        <div className='col-lg-6'>
          <Card className='d-flex align-items-center border-0 h-100 bg-transparent'>
            <Card.Body>
              <Card.Title className='text-center text-capitalize mb-5'>
                {title}
              </Card.Title>
              <Card.Text className='text-justify'>
                {text}
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className='col-lg-6'>
          <img src={image} alt={title} className='w-100' />
        </div>
      </div>
    </div>
  );
}

export default Vision;
