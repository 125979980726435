import './App.css';
import { Link, Routes, Route, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react'; // Import useState
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Home from './pages/Home/Home';
import Courses from './pages/Courses/Courses';
import About from './pages/About/About';
// import Blog from './pages/Blog/Blog';
import Contact from './pages/Contact/Contact';
import Fee from './pages/Fee/Fee';
import OurClasses from './pages/OurClasses/OurClasses';
import ApplyNow from './pages/Apply/ApplyNow';
import logo from './favicon-32x32.png';

function App() {
  const [expanded, setExpanded] = useState(false); // State to control navbar collapse
  const location = useLocation();

  const handleNavClose = () => setExpanded(false); // Function to close navbar

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the location changes
  }, [location]);


  return (
    <div className="App">
      <Navbar expand="lg" className='navbar navbar-light bg-secondary w-100 sticky-top' expanded={expanded}>
        <Container>
          <Navbar.Brand>
            <Link to="/" className='navbar-brand d-flex align-items-center' onClick={handleNavClose}>
              <img src={logo} alt='logo' className='logo' />
              {/* This is the page header */}
              <span className='mx-2 text-light lh-1 fw-semibold'>
                W.H.S
              </span>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' className='bg-light' onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto justify-content-end w-100'>
              <Nav.Link as={Link} to="/" className='text-uppercase' onClick={handleNavClose}>Home</Nav.Link>
              <Nav.Link as={Link} to="/about" className='text-uppercase' onClick={handleNavClose}>About Us</Nav.Link>
              <Nav.Link as={Link} to="/ourclasses" className='text-uppercase' onClick={handleNavClose}>Our Classes</Nav.Link>
              <Nav.Link as={Link} to="/courses" className='text-uppercase' onClick={handleNavClose}>Programmes</Nav.Link>
              <Nav.Link as={Link} to="/fee" className='text-uppercase' onClick={handleNavClose}>Fees</Nav.Link>
              {/* <Nav.Link as={Link} to="/blog" className='text-uppercase' onClick={handleNavClose}>News</Nav.Link> */}
              <Nav.Link as={Link} to="/apply" id='applyNow' className='text-uppercase mx-2' onClick={handleNavClose}>Apply Now</Nav.Link>
              <Nav.Link as={Link} to="/contact" id='contactUs' className='text-uppercase mx-2' onClick={handleNavClose}>Get in touch</Nav.Link>
              
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/courses' element={<Courses />} />
        <Route path='/ourclasses' element={<OurClasses />} />
        <Route path='/fee' element={<Fee />} />
        <Route path='/about' element={<About />} />
        {/* <Route path='/blog' element={<Blog />} /> */}
        <Route path='/contact' element={<Contact />} />
        <Route path='/apply' element={<ApplyNow />} />
      </Routes>

      <footer className='bg-dark text-light'>
      
        <div className='container'>
            <div className='row'>

            <div className='col-md-6 pt-5 align-items-center'>
                  <div className='container'>
                  <div className='container text-light text-start fs-5 pt-3'>

                    
                    <div className='d-flex flex-column gap-3 ms-5'>
                      <Link to="/" className="footlink text-decoration-none text-light">Home</Link>
                      <Link to="/about" className="footlink text-decoration-none text-light">About Us</Link>
                      <Link to="/ourclasses" className="footlink text-decoration-none text-light">Our Classes</Link>
                      <Link to="/courses" className="footlink text-decoration-none text-light">Programmes</Link>
                      <Link to="/fee" className="footlink text-decoration-none text-light">Fee Structure</Link>
                      <Link to="/apply" className="footlink text-decoration-none text-light">Apply Now</Link>
                      <Link to="/contact" className="footlink text-decoration-none text-light">Contact Us</Link>
                    </div>
                  </div>

                  </div>
                </div>


                
                <div className='col-md-6 pt-5'>

                  <div className='container'>
                    <div >
                      <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#dc3545" class="bi bi-telephone" viewBox="0 0 16 16">
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                        </svg>
                    </div>

                    <div className='p-3 '>

                    <p className='phones'>+254 706 321 788 / +254 723 114 959</p>

                    </div>
                    <div >
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#dc3545" class="bi bi-envelope" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                    </svg>
                    </div>

                    <div className='p-3'>

                    <p className='phones'>contact@woodfordhillschool.co.ke</p>

                    </div>
                  </div>
                </div>
                
                </div>

               
                




            </div>
        </div>

        <div className='container pt-5 '>
                  <iframe title="map" width={"100%"} height={"100%"} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8993087181702!2d36.67655708515246!3d-1.2297870323493045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1f3160dc5beb%3A0xc19d6f029c7b406f!2sWoodford%20Hill%20school!5e0!3m2!1sen!2sus!4v1719860401417!5m2!1sen!2sus" />
                  <br/><h5>Location: Gitaru</h5>
                </div>
        
        <div className='container d-flex justify-content-center align-items-center my-4 pt-5'>
            <Link to="/contact" className='pb-2'>
                <button type='button' className='btn btn-outline-light btn-lg'>Get In Touch</button>
            </Link>
        </div>
        
        <div className='bg-body-light pb-2'>
            <div className='container'>
                <p className='p-3 m-0 text-center'>© made by Kamenju</p>
            </div>
        </div>
    </footer>
    </div>
  );
}

export default App;
