import React from 'react';
import './About.css';
import Vision from '../../components/Visions/Vision';
import babies from "./babies.jpg";
import group from "./group.jpg";
import junior from "./class 8 - 2.jpg";
import teachers from "./teachers.jpg";
import assembly from "./assembly.jpg";


const sections = [
  { key: 'vision1', 
    title: 'Our Vision', 
    text: "To create a strong communal environment of social responsibility and highest possible academic results", 
    image: junior, 
    reverse: false, 
    bgSecondary: false 
  },
  { key: 'mission1', 
    title: 'Our Mission', 
    text: "To offer a homely atmosphere and a holistic education founded on strong principles of discipline, character and leadership", 
    image: group, 
    reverse: true, 
    bgSecondary: true 
  },
  { key: 'goal1', 
    title: 'Our Motto', 
    text: "Doing Your Best At All Times", 
    image: babies, 
    reverse: false, 
    bgSecondary: false 
  },
  { key: 'story1', 
    title: 'Our Story', 
    text: "Founded in 2009, Woodford Hill School aimed to provide quality education to the children of Kabete and Kikuyu subcounties. Starting with just a few classrooms, the school emphasized strong educational foundations and a nurturing environment. As enrollment grew, new classrooms were built, and more teachers were hired. The curriculum expanded to include French, computer studies, and chess, demonstrating the school’s commitment to a well-rounded education.", 
    image: assembly, 
    reverse: true, 
    bgSecondary: true 
  },
  { key: 'approach1', 
    title: 'Our Approach', 
    text: "At Woodford Hill School, our approach to education integrates a variety of innovative teaching methodologies to foster a dynamic and engaging learning environment. We emphasize personalized learning, tailoring instruction to meet the unique needs and strengths of each student. Our project-based learning framework encourages hands-on, real-world problem-solving, making education relevant and exciting. Through blended learning, we incorporate online resources, digital tools, and flipped classrooms to create a flexible and interactive learning experience. Continuous formative assessments and peer evaluations ensure ongoing feedback and growth. We follow the CBC curriculum, which promotes critical thinking and creativity, and we prioritize collaborative learning to build teamwork and communication skills among our students.", 
    image: teachers, 
    reverse: false, 
    bgSecondary: false 
  }
];

function About() {
  return (
    <div className='about-page'>
      <header className='height-30'>
        <div className='container pt-5 text-light'>
          <h1 className='text-center fw-semibold'>About Us</h1>
          <p className='text-center mb-5'>Discover our mission and commitment to nurturing growth and academic excellence for every student.</p>
        </div>
      </header>
      {sections.map((section) => (
        <Vision 
          key={section.key} 
          title={section.title} 
          text={section.text} 
          image={section.image} 
          reverse={section.reverse} 
          bgSecondary={section.bgSecondary} 
        />
      ))}
    </div>
  );
}

export default About;
