import React from 'react'
import './Fee.css'
import { Card } from 'react-bootstrap';
import FaqAccordion from '../../components/FaqAccordion/FeeAccordion'

function Fee() {
  return (
    <div className='fee-page'>
      <header className='height-30'>
        <div className='container pt-5 text-light'>
          <h1 className='text-center fw-semibold'>Fee</h1>
          <p className='text-center mb-5'>Discover our detailed fee structure and additional costs to aid your financial planning decisions.</p>
        </div>
      </header>
      <div className='container py-5'>
        <div className='container fee-table'>
          <Card className='border-0 shadow fee-card'>
            <h2 className='text-center text-capitalize mb-3 text-danger fw-semibold mt-3'>Pre-School</h2>
            <table>
              <thead className='bg-danger bg-opacity-75'>
                <tr>
                  <th>Grade</th>
                  <th>Fees</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Play Group</td>
                  <td>Ksh. 17,000/=</td>
                </tr>
                <tr>
                  <td>PP1</td>
                  <td>Ksh. 17,000/=</td>
                </tr>
                <tr>
                  <td>PP2</td>
                  <td>Ksh. 17,000/=</td>
                </tr>
              </tbody>
            </table>
            <div className='container row'>
              <p className='text-center mt-3 col-6'>Interviews - 1,000/= </p>
              <p className='text-center mt-3 col-6'>Registration - 3,000/=</p>
              <p className='text-center mt-3 col-6'>Swimming - 2,500/= per Term</p>
              <p className='text-center mt-3 col-6'>Exam Fee - 500/=</p>
            </div>
          </Card>
        </div>

        <div className='container fee-table'>
          <Card className='border-0 mt-5 shadow fee-card'>
            <h2 className='text-center text-capitalize mb-3 text-danger fw-semibold mt-3'>Primary</h2>
            <table>
              <thead className='bg-danger bg-opacity-75'>
                <tr>
                  <th>Grade</th>
                  <th>Fees</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Grade 1 - 3</td>
                  <td>Ksh. 18,000/=</td>
                </tr>
                <tr>
                  <td>Grade 4 - 6</td>
                  <td>Ksh. 19,000/=</td>
                </tr>
              </tbody>
            </table>
            <div className='container row'>
              <p className='text-center mt-3 col-6'>Interviews - 1,000/= </p>
              <p className='text-center mt-3 col-6'>Registration - 3,000/=</p>
              <p className='text-center mt-3 col-6'>Swimming - 2,500/= per Term</p>
              <p className='text-center mt-3 col-6'>Exam Fee - 500/=</p>
            </div>
          </Card>
        </div>

        <div className='container fee-table'>
          <Card className='border-0 mt-5 shadow fee-card'>
            <h2 className='text-center text-capitalize mb-3 text-danger fw-semibold mt-3'>Junior School</h2>
            <table>
              <thead className='bg-danger bg-opacity-75'>
                <tr>
                  <th>Grade</th>
                  <th>Fees</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Grade 7</td>
                  <td>Ksh. 25,000/=</td>
                </tr>
                <tr>
                  <td>Grade 8</td>
                  <td>Ksh. 25,000/=</td>
                </tr>
              </tbody>
            </table>
            <div className='container row'>
              <p className='text-center mt-3 col-6'>Interviews - 2,000/= </p>
              <p className='text-center mt-3 col-6'>Registration - 3,000/=</p>
              <p className='text-center mt-3 col-6'>Swimming - 2,500/= per Term</p>
              <p className='text-center mt-3 col-6'>Exam Fee - 500/=</p>
            </div>
          </Card>
        </div>

        <div className='container fee-table'>
          <Card className='border-0 mt-5 shadow fee-card'>
            <h2 className='text-center text-capitalize mb-3 text-danger fw-semibold mt-3'>Transport</h2>
            <table>
              <thead className='bg-danger bg-opacity-75'>
                <tr>
                  <th>Zone</th>
                  <th>Fee per Term</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Zone 1</td>
                  <td>Ksh. 4,500/=</td>
                </tr>
                <tr>
                  <td>Zone 2</td>
                  <td>Ksh. 6,000/=</td>
                </tr>
                <tr>
                  <td>Zone 3</td>
                  <td>Ksh. 7,500/=</td>
                </tr>
                <tr>
                  <td>Zone 4</td>
                  <td>Ksh. 12,000/=</td>
                </tr>
              </tbody>
            </table>
            <div>
              <p className='text-start mx-5 mt-3'>Zone 1 = Gitaru, Ufanisi, Muthure, Mbari ya Njau</p>
              <p className='text-start mx-5'>Zone 2 = Regen, Rungiri, Rukubi, Maina Road, Kanjeru</p>
              <p className='text-start mx-5'>Zone 3 = Kikuyu, Muthiga, Kahuho, Wangige, Kingeero</p>
              <p className='text-start mx-5'>Zone 4 = Nderi, Kiambaa, Zambezi, Sigona</p>
            </div>
          </Card>
        </div>

        <div className='container fee-table'>
          <Card className='border-0 mt-5 shadow fee-card'>
            <h2 className='text-center text-capitalize mb-3 text-danger fw-semibold mt-3'>Uniform</h2>
            <p className='text-start m-3 fw-semibold'>Boys - Uniform:</p>
            <table>
              <thead className='bg-danger bg-opacity-75'>
                <tr>
                  <th>Item</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Shirt</td>
                  <td>400/=</td>
                </tr>
                <tr>
                  <td>Sweater</td>
                  <td>900/=</td>
                </tr>
                <tr>
                  <td>Trouser</td>
                  <td>700/=</td>
                </tr>
                <tr>
                  <td>Tracksuit</td>
                  <td>1,400/=</td>
                </tr>
                <tr>
                  <td>P.E T/Shirt</td>
                  <td>400/=</td>
                </tr>
                <tr>
                  <td>FLeece Jacket</td>
                  <td>1,700/=</td>
                </tr>
              </tbody>
            </table>
            <p className='text-start m-3 fw-semibold'>Girls - Uniform:</p>
            <table>
              <thead className='bg-danger bg-opacity-75'>
                <tr>
                  <th>Item</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Dress</td>
                  <td>900/=</td>
                </tr>
                <tr>
                  <td>Sweater</td>
                  <td>900/=</td>
                </tr>
                <tr>
                  <td>Tracksuit</td>
                  <td>1,400/=</td>
                </tr>
                <tr>
                  <td>P.E T/Shirt</td>
                  <td>400/=</td>
                </tr>
                <tr>
                  <td>FLeece Jacket</td>
                  <td>1,700/=</td>
                </tr>
              </tbody>
            </table>
          </Card>
        </div>


        <div className='container'>
          <h4 className='text-start text-capitalize mt-5 mx-3'>Fee Payment:</h4>

          <p className='text-start mx-3'>KCB Account No.: <span className='fw-semibold fs-5'>1295162733</span></p>
          <p className='text-start mx-3'>School fee is strictly payable through our school account or our Mpesa Paybill</p>
          <p className='text-start mx-3'>Mpesa Paybill No.: <span className='fw-semibold fs-5'>522123</span></p>
          <p className='text-start mx-3'>Mpesa Account No.: <span className='fw-semibold fs-5'>88095K </span><span>followed by the name of the student and Grade. Example: 88095KBrianG2</span></p>
        </div>
        


      </div>

      <div className='py-5 bg-secondary bg-opacity-25'>
        <FaqAccordion />
      </div>
    </div>
  )
}

export default Fee
