import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import ChooseSection from '../../components/ChooseSection/ChooseSection';
import StartCoursesImg from './pre-school.jpg';
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';
// import { Card } from 'react-bootstrap';
// import Blog1Img from './../images/blog1-img.jpg';
// import Blog2Img from './../images/blog2-img.jpg';
// import Blog3Img from './../images/blog3-img.jpg';

// const blogs = [
//     {
//         id: 1,
//         img: [Blog1Img],
//         title: 'Blog 1',
//         description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, fugit? Doloremque deserunt ipsum eaque, dolor tempore, minima nisi debitis, et quas voluptatibus nam ex. Necessitatibus eligendi ratione expedita! Porro, ut.'
//     },
//     {
//         id: 2,
//         img: [Blog2Img],
//         title: 'Blog 2',
//         description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, fugit? Doloremque deserunt ipsum eaque, dolor tempore, minima nisi debitis, et quas voluptatibus nam ex. Necessitatibus eligendi ratione expedita! Porro, ut.'
//     },
//     {
//         id: 3,
//         img: [Blog3Img],
//         title: 'Blog 3',
//         description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, fugit? Doloremque deserunt ipsum eaque, dolor tempore, minima nisi debitis, et quas voluptatibus nam ex. Necessitatibus eligendi ratione expedita! Porro, ut.'
//     }
// ];

function Home() {
  return (
    <div className='home-page'>
      <header className='height-92 min-vh-92.1  d-flex align-items-center text-light'>
            <div className='container d-flex flex-column align-items-center'>
                <h2>Welcome To</h2>
                <h1 className='text-center fw-semibold my-3'>WoodFord Hill School</h1>
                <p>Doing Your Best At All Times</p>
                <div className='d-flex flex-column flex-sm-row align-items-center'>
                    <Link to="/about">
                        <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>About Us</button>
                    </Link>
                    <Link to="/apply">
                        <button type='button' className='btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Apply Now</button>
                    </Link>
                </div>
            </div>
        </header>

        <div className='py-5'>
          <ChooseSection />
        </div>

        <div className='py-5 bg-secondary bg-opacity-25'>
            <div className="container">
                <div className='row d-flex align-items-center justify-content-around'>
                    <div className='col-lg-5'>
                        <h2 className='text-capitalize'>2024 Enrollment</h2>
                        <p>Join Woodford Hill School in 2024 and embark on an enriching educational journey focused on academic excellence and holistic development.</p>
                        <Link to="/ourclasses">
                            <button type='button' className='btn btn-outline-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Learn More</button>
                        </Link>
                    </div>
                    <div className='col-lg-5 mt-5 mt-lg-0'>
                        <img src={StartCoursesImg} className='img-fluid' alt="" />
                    </div>
                </div>
            </div>
        </div>

        <div className='py-5'>
          <FaqAccordion />
        </div>

        {/* <div className='blog-section text-light py-5'>
            <div className='container d-flex flex-column align-items-center'>
                <h2 className='text-center text-capitalize mb-5'>Latest on the blog</h2>
                <div className='row g-4'>
                    {blogs.map((blog) => (
                        <div key={blog.id} className='col-md-6 col-lg-4 scalar'>
                            <Link to="/blog" className='text-decoration-none'>
                                <Card className='h-100 shadow scale-hover-effect'>
                                    <Card.Img variant="top" src={blog.img} />
                                    <Card.Body className='p-md-5'>
                                        <Card.Title>{blog.title}</Card.Title>
                                        <Card.Text>{blog.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                    ))}
                </div>
                <Link to="/blog">
                    <button type='button' className='btn btn-outline-danger btn-lg mt-5'>Read More Blogs</button>
                </Link>
            </div>
        </div> */}
        
    </div>
  )
}

export default Home;